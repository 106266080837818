import React from 'react'
import styled from 'styled-components'
import Lottie from "lottie-react";
import headerImage from "./images/cat2.json";
import { Link, Text } from '../../'
import TwitterSvg from './images/TwitterSvg'
import TelegramSvg from './images/TelegramSvg'
import DiscordSvg from './images/DiscordSvg'
import MediumSvg from './images/MediumSvg'
import LogoSvg from './images/LogoSvg'

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 32px 16px;
  background: #121212;
  width: 100%;
  padding: 36px 64px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 36px 128px;
    flex-direction: row;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    padding: 36px 142px;
  }
`

const UsefulWrapper = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.xl} {
    width: 70%;
  }
`

const SocialAndLogoWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 32px;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
    justify-content: space-evenly;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    justify-content: space-between;
  }
`

const SocialsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 32px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 0;
  }
`
const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const DataColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 150px;
  padding-bottom: 32px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    align-items: flex-start;
    justify-content: space-evenly;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  padding-bottom: 32px;

  ${({ theme }) => theme.mediaQueries.xl} {
    align-items: flex-end;
    justify-content: center;
    width: 20%;
  }
`

const MonsterWrapper = styled.div`
  display: none;
  width: 30%;

  ${({ theme }) => theme.mediaQueries.xl} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
  }
`

const imagePath = '/images/'
const imageSrc = 'cube'

const LicoFooter = () => {

  return (
    <>
      <Wrapper>
        <UsefulWrapper>
          <SocialAndLogoWrapper>
            <LogoSvg />
            <SocialsWrapper>
              <Link external mr="12px" href="https://twitter.com/CronosCats">
                <TwitterSvg />
              </Link>
              <Link external mr="12px" href="https://t.me/cronoscats">
                <TelegramSvg />
              </Link>
              <Link external mr="12px" href="https://discord.gg/vdr5F3SG4d">
                <DiscordSvg />
              </Link>
              <Link external href="https://cronoscats.medium.com">
                <MediumSvg />
              </Link>
            </SocialsWrapper>
          </SocialAndLogoWrapper>
          <DataWrapper>
            <DataColumn>
              <Text fontSize="14px" mb="12px" color="primary">
                {'INFO'}
              </Text>
              <Link small color="text" external href="https://docs.cronoscats.club/cronos-cats-ecosystem/ccc-tokenomics">
                {'Token'}
              </Link>
              <Link small color="text" external href="https://ido.cronoscats.club/launchpad">
                {'Presale'}
              </Link>
              <Link small color="text" external href="https://docs.cronoscats.club/cronos-cats-club-intro/roadmap">
                {'Roadmap'}
              </Link>
            </DataColumn>
            <DataColumn>
              <Text fontSize="14px" mb="12px" color="primary">
                {'RESOURCES'}
              </Text>
             <Link small color="text" external href="https://github.com/cronoscatsclub">
             {'Github'}
              </Link>
              <Link small color="text" external href="https://docs.cronoscats.club">
                {'Docs'}
              </Link>
            </DataColumn>
            <ButtonWrapper>
              <Link external href="https://cronoscats.club">
              <img src='/images/powered.png' width={173} alt='Logo'/>
              </Link>
            </ButtonWrapper>
          </DataWrapper>
        </UsefulWrapper>
        <MonsterWrapper>
        <Lottie animationData={headerImage} loop={true}  style={{ maxWidth: '150px' }} />
        </MonsterWrapper>
      </Wrapper>
    </>
  )
}

export default LicoFooter
