import { Token, ChainId } from '@cronoscatsclub/nftsdk'
import tokens from './tokens'
import farms from './farms'
import { Ifo, Minting } from './types'
import {  getDccNftAddress, getCcmpNftAddress, getPndmNftAddress  } from 'utils/addressHelpers'

//export const cakeBnbLpToken = new Token(ChainId.MAINNET, farms[1].lpAddresses[ChainId.MAINNET], 18, farms[1].lpSymbol)

const collectionLinks = {
  'CCMP': 'https://minted.network/collections/cronos/0x9b389aebc343c6b759e23334733f32d779595ea5',
  'PNDM': 'https://minted.network/collections/cronos/0xF298a3e3ef422DA5aAb4A147De78D45fef49a26a',

}

const ifos: Minting[] = [
 //============================LIVE MINT NFTS====================================
  {
    id: 'ccmpNFT',
    stake_pid: 1,
    name: 'Membership Pass',
    description: 'Cronos Cats Membership Pass provides you with exclusive access to the entire project ecosystem. Explore the innovative realm of NFT Membership, where ownership meets seamless utility.',
    address: getCcmpNftAddress(),
    symbol: 'CCMP',
    totalSupply: 500,
    lastPrice: 600,
    isActive: true,
    status: 'livepublic',
    avatar: "/images/stakenft/cronoscatsclub.png",
    banner: {
      large: "/images/stakenft/bigbanner2.png",
      small: "/images/stakenft/logos/ccmpbanner.png",
    },
    sampleNftImage: { 'tokenId': 1, 'image': 'https://ipfs.io/ipfs/bafybeibobrwbhfh4523dam6icnfmpvh5za477knbst6azj6dt2h3gbab3m/ruby.png', 'link': collectionLinks['ccmpNFT'] },
    faq: [
      {
        title: "What is a Cronos Cats Membership Pass?",
        description: ["Cronos Cats Membership Pass provides you with exclusive access to the entire project ecosystem. Free minting of any NFTs that will appear on the Cronos Cats platform. Exclusive airdrops of tokens that will be launched on the Cronos Cats platform. Special high yield farms exclusively for Cronos Cats Membership Pass. The ability to launch your projects for free using the Cronos Cats Club ecosystem on an ongoing basis. "]
      },
      {
        title: "How can I acquire these NFTs?",
        description: ["This membership card can only be minted through the Cronos Cats NFT Platform. Minting is not possible from any other source."]
      },
      {
        title: "What is the pricing and supply?",
        description: ["The  Cronos Cats Club Membership Pass NFT has a dynamic price. As the number of NFTs remaining for minting decreases, the price increases. Cronos Cats Club NFT holders can acquire this membership pass with a 50% discount. There will be a total of 500 Cronos Cats Membership Pass available."]
      },
      {
        title: "Can I use Cronos Cats Membership Pass on other platforms?",
        description: ["Yes, you certainly can. Cronos Cats Membership Pass NFTs are built on the Cronos chain and are thus compatible with any other marketplaces that support Cronos chain, such as Minted NFT Marketplace."]
      },
    ],
    poolUnlimited: {
      saleAmount: '500 NFT',
      distributionRatio: 1,
    },
    currency: tokens.cake,
    token: tokens.linbing,
    releaseBlockNumber: 19314716,
    articleUrl: 'https://cronoscats.club',
    version: 3.1,
    openSeaUrl: 'https://minted.network/collections/cronos/0xF298a3e3ef422DA5aAb4A147De78D45fef49a26a',
    discordUrl: 'https://discord.gg/d2jMeH3byS',
    telegramUrl: 'https://t.me/cronoscats',
    twitterUrl: 'https://twitter.com/CronosCats',
  },

  //============================PADAMONIUM NFTS====================================
  {
    id: 'pndmNFT',
    stake_pid: 3,
    name: 'Pandamonium NFT',
    description: 'Unique collection of 7777 cool pandas created by artificial intelligence for Cronos enthusiasts by the Cronos Cats Club team. Join the meme movement and become a contributor today!',
    address: getPndmNftAddress(),
    symbol: 'PNDM',
    totalSupply: 7777,
    lastPrice: 100,
    isActive: true,
    status: 'livepublic',
    avatar: "/images/stakenft/pndm.png",
    banner: {
      large: "/images/stakenft/minted3.png",
      small: "/images/stakenft/logos/bigpanda.png",
    },
    sampleNftImage: { 'tokenId': 101, 'image': 'https://nftstorage.link/ipfs/bafybeigbye2jplyu4knddb6252bl3hbkj66qpxnxaiqj4kurq7dzb73k7y/70.png', 'link': collectionLinks['ccmpNFT'] },
    faq: [
      {
        title: "What is Pandamonium?",
        description: ["Pandamonium is a completely community-driven project where the launch of an NFT collection is the progenitor of the MEME $PNDM token. The owners of Pandamonium are directly involved in the development and growth of the project."]
      },
      {
        title: "How can I acquire these NFTs?",
        description: ["This Pandamonium NFTs can only be minted through the Cronos Cats NFT Platform. Minting is not possible from any other source."]
      },
      {
        title: "What is the pricing and supply?",
        description: ["The price of 1 Pandamonium NFT = 100 $CRO. Cronos Cats Club and Membership Pass NFTs holders can acquire Pandamonium NFTs with a 20% discount."]
      },
      {
        title: "Can I use Pandamonium NFTs on other platforms?",
        description: ["Yes, you certainly can. Pandamonium NFTs are built on the Cronos chain and are thus compatible with any other marketplaces that support Cronos chain, such as Minted NFT Marketplace."]
      },
    ],
    poolUnlimited: {
      saleAmount: '7777 NFT',
      distributionRatio: 1,
    },
    currency: tokens.cake,
    token: tokens.linbing,
    releaseBlockNumber: 15156634,
    articleUrl: 'https://cronoscats.club/',
    version: 3.2,
    openSeaUrl: 'https://minted.network/collections/cronos/0xf298a3e3ef422da5aab4a147de78d45fef49a26a',
    discordUrl: 'https://discord.gg/d2jMeH3byS',
    telegramUrl: 'https://t.me/cronoscats',
    twitterUrl: 'https://twitter.com/CronosCats',
  },

]

export default ifos
