import { Box, Button, Flex, InjectedModalProps, LinkExternal, Message, Skeleton, Text } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import tokens from 'config/constants/tokens'
import { FetchStatus } from 'config/constants/types'
import { useTranslation } from 'contexts/Localization'
import useAuth from 'hooks/useAuth'
import useTokenBalance, { useGetBnbBalance, useGetCroBalance } from 'hooks/useTokenBalance'

import { getBscScanLink, getCronosScanLink } from 'utils'
import { formatBigNumber, getFullDisplayBalance } from 'utils/formatBalance'
import CopyAddress from './CopyAddress'

interface WalletInfoProps {
  hasLowCroBalance: boolean
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowCroBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  //const { balance, fetchStatus } = useGetBnbBalance()
  const { balance, fetchStatus } = useGetCroBalance()
  const { balance: wCROBalance, fetchStatus: wCROFetchStatus } = useTokenBalance(tokens.wcro.address)
  const { balance: PNDMBalance, fetchStatus: PNDMFetchStatus } = useTokenBalance(tokens.pndm.address)
  const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useTokenBalance(tokens.ccc.address)
  const { logout } = useAuth()

  const handleLogout = () => {
    onDismiss?.()
    logout()
  }

  return (
    <>
      <Text color="secondary" fontSize="12px" textTransform="uppercase" fontWeight="bold" mb="8px">
        {t('Your Address')}
      </Text>
      <CopyAddress account={account} mb="24px" />
      {hasLowCroBalance && (
        <Message variant="warning" mb="24px">
          <Box>
            <Text fontWeight="bold">{t('CRO Balance Low')}</Text>
            <Text as="p">{t('You need CRO for transaction fees.')}</Text>
          </Box>
        </Message>
      )}
      <Flex alignItems="center" justifyContent="space-between">
      <Text color="#a3df56" fontWeight={600}>{'CRO Balance:'}</Text>
        {fetchStatus !== FetchStatus.Fetched ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text>{formatBigNumber(balance, 3)}</Text>
        )}
      </Flex>
      {wCROBalance.gt(0) && (
          <Flex alignItems="center" justifyContent="space-between">
           <Text color="#a3df56" fontWeight={600}>
          {'WCRO Balance:'}</Text>
            {wCROFetchStatus !== FetchStatus.Fetched ? (
              <Skeleton height="22px" width="60px" />
            ) : (
              <Text>{getFullDisplayBalance(wCROBalance, 18, 3)}</Text>
            )}
          </Flex>
        )}
        {/*  {PNDMBalance.gt(0) && (
          <Flex alignItems="center" justifyContent="space-between">
           <Text color="#a3df56" fontWeight={600}>
          {'PNDM Balance:'}</Text>
            {PNDMFetchStatus !== FetchStatus.Fetched ? (
              <Skeleton height="22px" width="60px" />
            ) : (
              <Text>{getFullDisplayBalance(PNDMBalance, 18, 3)}</Text>
            )}
          </Flex> 
        )} */}
      <Flex alignItems="center" justifyContent="space-between" mb="24px">
        <Text color="#a3df56" fontWeight={600}>
          {'CCC Balance:'}</Text>
        {cakeFetchStatus !== FetchStatus.Fetched ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text>{getFullDisplayBalance(cakeBalance, 18, 3)}</Text>
        )}
      </Flex>
    

      <Flex alignItems="center" justifyContent="end" mb="24px">
        <LinkExternal href={getCronosScanLink(account, 'address')}>{t('View on Cronos Explorer')}</LinkExternal>
      </Flex>
      <Button variant="secondary" width="100%" onClick={handleLogout}>
        {t('Disconnect Wallet')}
      </Button>
    </>
  )
}

export default WalletInfo
