import React from 'react'

const TwitterSvg = (props) => (
  <svg data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" height="44px" {...props}>
    <defs>
      <linearGradient
        id="a"
        y1={192.51}
        x2={44}
        y2={192.51}
        gradientTransform="matrix(1 0 0 -1 0 214.51)"
        gradientUnits="userSpaceOnUse"
      >
    <stop offset={0} stopColor="#ffffff" />
        <stop offset={1} stopColor="#ffffff" />
      </linearGradient>
    </defs>
    <circle
      cx={22}
      cy={22}
      r={21.5}
      style={{
        fill: 'none',
        stroke: 'url(#a)',
      }}
    />
    <path
      d="M33.64 15.94a9.18 9.18 0 0 1-2.67.73A4.69 4.69 0 0 0 33 14.09a9.12 9.12 0 0 1-3 1.13 4.66 4.66 0 0 0-2.65-1.41 4.66 4.66 0 0 0-5 2.68 4.69 4.69 0 0 0-.29 3 13.2 13.2 0 0 1-5.32-1.41 13.28 13.28 0 0 1-4.28-3.46 4.67 4.67 0 0 0-.08 4.54A4.55 4.55 0 0 0 14 20.82a4.6 4.6 0 0 1-2.11-.58v.06a4.66 4.66 0 0 0 3.74 4.57 4.85 4.85 0 0 1-2.11.08 4.7 4.7 0 0 0 1.66 2.31 4.6 4.6 0 0 0 2.69.92 9.32 9.32 0 0 1-5.78 2 8.27 8.27 0 0 1-1.11-.07A13.24 13.24 0 0 0 31.33 19v-.6a9.33 9.33 0 0 0 2.32-2.41Z"
      style={{
        fill: '#fff',
        fillOpacity: 0.87,
      }}
    />
  </svg>
)

export default TwitterSvg
