import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  DropdownMenuItems,
  InfoIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  ContextApi,
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (chainId) =>
  [
    {
      label: 'Trade',
      icon: 'Swap',
      href: '/swap',
      showItemsOnMobile: false,
      items: [
        {
          label: 'Exchange',
          href: '/swap',
        },
        {
          label: 'Liquidity',
          href: '/liquidity',
        },
      ],
    },
    {
      label: 'Earn',
      href: '/farms',
      icon: 'Earn',
      items: [
        {
          label: 'Farms',
          href: '/farms',
        },
        {
          label: 'Pools',
          href: '/pools',
        },
      ],
    },
   {
      
      label: 'Mint',
      icon: 'Nft',
      href: '/nfts',
      initialOpenState: true,
      items: [
          {
              label: 'Mint',
              href: '/nfts/collections',
          },  
          {
            label: 'Claim Rewards',
            href: '/claim',
        },          

      ],
  },
   
  
    {
      label: 'NFT Staking',
      icon: 'StarFill',
      href: '/nft-staking',
      fillIcon: NftFillIcon,
      items: [
        {
          label: 'Club Staking',
          href: '/nft-staking',
        },
      ],
    },
    {
      label: 'More',
      icon: 'Info',
      hideSubNav: true,
      items: [
        {
          label: '$CCC Presale',
          href: 'https://ido.cronoscats.club/launchpad',
          type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: 'Cronos Cats NFT',
        href: 'https://nft.cronoscats.club/mint',
        type: DropdownMenuItemType.EXTERNAL_LINK,
    },
        {
          label: 'Trade Cronos Cats',
          href: 'https://minted.network/collections/cronos/0xa2ebd9d8a5829e64598fb6159d5a187c73a4029a',
          type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: 'Trade Membership Pass',
        href: 'https://minted.network/collections/cronos/0x9b389aebc343c6b759e23334733f32d779595ea5',
        type: DropdownMenuItemType.EXTERNAL_LINK,
    },
    {
      label: 'Trade Pandamonium',
      href: 'https://minted.network/collections/cronos/0xf298a3e3ef422da5aab4a147de78d45fef49a26a',
      type: DropdownMenuItemType.EXTERNAL_LINK,
  },
      
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
