import { Colors } from "./types";

export const baseColors = {
  failure: "#ED4B9E",
  primary: "#50bce6",
  primarygreen: "#3f4c5e",
  primaryBright: "#EC407A",
  primaryDark: "#D81B60",
  secondary: "#a3df56",
  success: "#31D0AA",
  warning: "#FFB237",
};

export const additionalColors = {
  binance: "#F0B90B",
  overlay: "#452a7a",
  gold: "#FFC700",
  silver: "#B2B2B2",
  bronze: "#E7974D",
};

export const lightColors: Colors = {
  ...baseColors,
  ...additionalColors,
  background: "#151c21",
  bordercolor: "#ffffff",
  backgroundDisabled: "#E9EAEB",
  backgroundAlt: "#111418",
  backgroundDark: "#1a1a1a",
  backgroundAlt2: "#1F2C3D;",
  cardBorder: "#6d6f89",
  contrast: "#191326",
  dropdown: "#151c21",
  dropdownDeep: "#EEEEEE",
  invertedContrast: "#FFFFFF",
  input: "#eeeaf4",
  inputSecondary: "#b8e5ff",
  tertiary: "#223e62",
  text: "#FFFFFF",
  textDisabled: "#BDC2C4",
  textSubtle: "#b8e5ff",
  textMain: "#ffffff",
  disabled: "#E9EAEB",
  gradients: {
    bubblegum: "linear-gradient(139.73deg,#3f4c5e 0%,#3f4c5e 100%)",
    inverseBubblegum: "linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)",
    cardHeader: "linear-gradient(111.68deg,#8BC3FF 0%,#1E2966 100%)",
    cardHeader2: "linear-gradient(111.68deg,#2a323e 0%,#2a323e 100%)",
    blue: "linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)",
    violet: "linear-gradient(180deg, #50bce6 0%, #C81E58)",
    violetAlt: "linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)",
    gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)",
  },
};

export const darkColors: Colors = {
  ...baseColors,
  ...additionalColors,
  secondary: "#a3df56",
  bordercolor: "#a3df56",
  background: "#1E1D20",
  backgroundDisabled: "#3c3742",
  backgroundDark: "#151c21",
  backgroundAlt: "#000",
  textMain: "#ffffff",
  backgroundAlt2: "rgba(39, 38, 44, 0.7)",
  cardBorder: "#383241",
  contrast: "#FFFFFF",
  dropdown: "#1E1D20",
  dropdownDeep: "#100C18",
  invertedContrast: "#191326",
  input: "#372F47",
  inputSecondary: "#262130",
  primaryDark: "#0098A1",
  tertiary: "#353547",
  text: "#F4EEFF",
  textDisabled: "#666171",
  textSubtle: "#B8ADD2",
  disabled: "#524B63",
  gradients: {
    bubblegum: "linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)",
    inverseBubblegum: "linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)",
    cardHeader: "linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)",
    cardHeader2: "linear-gradient(111.68deg,#2a323e 0%,#2a323e 100%)",
    blue: "linear-gradient(180deg, #00707F 0%, #19778C 100%)",
    violet: "linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)",
    violetAlt: "linear-gradient(180deg, #434575 0%, #66578D 100%)",
    gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)",
  },
};
