import { serializeTokens } from './tokens'
import { SerializedNftFarmConfig } from './types'
import { CHAIN_ID } from './networks'
import { ChainId } from '@cronoscatsclub/nftsdk'

const serializedTokens = serializeTokens()

const nftFarms: SerializedNftFarmConfig[] = [
  

  {
    pid: 1,
    lpSymbol: 'Cronos Cats Club',
    nftAddresses: {
      25: '0xA2EBd9D8A5829E64598Fb6159d5a187C73A4029a',
      80001: '0xA2EBd9D8A5829E64598Fb6159d5a187C73A4029a'
    },
    contractAddresses: {
      25: '0xd8A994415aCa3e883ab3A00Bc6059ABcC1792832',
      80001: '0xd8A994415aCa3e883ab3A00Bc6059ABcC1792832',
    },
    tokenPerBlock: '175',
    participantThreshold: 5000,
    isFinished: false,
    earningToken: serializedTokens.ccc,
   // sideRewards: [
   //   {token: 'CRO', percentage: 0},
  //  ],
  //  supportedCollectionPids: [1],
    mainCollectionWeight: '1',
    performanceFee: '0',
    projectLink: 'https://cronoscats.club',
    banner: "/images/stakenft/logos/ccbig2.png",
    avatar: "https://cronoscats.club/cronoscats.png",
   //staticNftImage: "/images/stakenft/logos/cctstake.png",
   //useApi: true,
  },
  {
    pid: 2,
    lpSymbol: 'Membership Pass',
    nftAddresses: {
      25: '0x9b389aeBC343C6B759E23334733F32D779595ea5',
      80001: '0x9b389aeBC343C6B759E23334733F32D779595ea5'
    },
    contractAddresses: {
      25: '0x9283E40D398941A8e36f33bdc33F1394ED148617',
      80001: '0x9283E40D398941A8e36f33bdc33F1394ED148617',
    },
    tokenPerBlock: '0.11',
    participantThreshold: 500,
    isFinished: false,
    earningToken: serializedTokens.wcro,
    sideRewards: [
    {token: 'CCC', percentage: 1000},
    ],
   // supportedCollectionPids: [2],
    mainCollectionWeight: '1',
    performanceFee: '0',
    projectLink: 'https://cronoscats.club',
    banner: "/images/stakenft/logos/ccmpbig.png",
    avatar: "https://cronoscats.club/images/tokens/nfts/0x9b389aeBC343C6B759E23334733F32D779595ea5.png",
   //staticNftImage: "/images/stakenft/logos/cctstake.png",
   //useApi: true,
  },
  {
    pid: 3,
    lpSymbol: 'Pandamonium',
    nftAddresses: {
      25: '0xf298a3e3ef422da5aab4a147de78d45fef49a26a',
      80001: '0xf298a3e3ef422da5aab4a147de78d45fef49a26a'
    },
    contractAddresses: {
      25: '0x9b066f8D0455a3b805706a40e0C4ec6c245DB643',
      80001: '0x9b066f8D0455a3b805706a40e0C4ec6c245DB643',
    },
    tokenPerBlock: '150',
    participantThreshold: 7777,
    isFinished: false,
    earningToken: serializedTokens.ccc,
   // sideRewards: [
   //   {token: 'CRO', percentage: 0},
  //  ],
  //  supportedCollectionPids: [1],
    mainCollectionWeight: '1',
    performanceFee: '0',
    projectLink: 'https://cronoscats.club',
    banner: "/images/stakenft/logos/pndmbig2.png",
    avatar: "/images/stakenft/logos/pndm.png",
  },


].filter((f) => !!f.nftAddresses[ChainId.CRONOS])

export default nftFarms
