import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'
import { ChainId } from '@cronoscatsclub/nftsdk'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1, 2) should always be at the top of the file.
   */
  // CAUTION: We dont'need pool zero for farm
  /*
  {
    pid: 0,
    lpSymbol: 'CCC',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      80001: '0xE564106bacd4D3b74a79e0fbDabF0c43828a1DBB' // CHANGE_ADDRESS:CollectToken (test)
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wbnb,
  },
  */
  {
    pid: 1,
    lpSymbol: 'CCC-USDT LP',
    lpAddresses: {
      97: '0xC2f155fa7522Be94DdFa5c5f100083D3C2fc6Abf',
      56: '0xC2f155fa7522Be94DdFa5c5f100083D3C2fc6Abf',
      25: '0xC2f155fa7522Be94DdFa5c5f100083D3C2fc6Abf',
      80001: '0xC2f155fa7522Be94DdFa5c5f100083D3C2fc6Abf' // CHANGE_ADDRESS:Pair
    },
    token: serializedTokens.ccc,
    quoteToken: serializedTokens.usdt,
  },
  {
    pid: 2,
    lpSymbol: 'CCC-CRO LP',
    lpAddresses: {
      97: '0xff3AD927784613ACC0814D4DFfb471a485b1E1D9',
      56: '0xff3AD927784613ACC0814D4DFfb471a485b1E1D9',
      25: '0xff3AD927784613ACC0814D4DFfb471a485b1E1D9',
      80001: '0xff3AD927784613ACC0814D4DFfb471a485b1E1D9' // CHANGE_ADDRESS:Pair
    },
    token: serializedTokens.ccc,
    quoteToken: serializedTokens.wcro,
  },
  
].filter((f) => !!f.lpAddresses[ChainId.CRONOS])

export default farms
