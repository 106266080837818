import { serializeTokens } from './tokens'
import { SerializedNftFarmGangverseConfig } from './types'
import { ChainId } from '@cronoscatsclub/nftsdk'

const serializedTokens = serializeTokens()

const nftFarmsGangverse: SerializedNftFarmGangverseConfig[] = [
  

{
    pid: 1,
    lpSymbol: 'Gangverse Darkside NFT',
    nftAddresses: {
      25: '0xC376D7a55a3Fb6FA0B12D90fd96FeFB0d96702bF',
    },
    contractAddresses: {
      25: '0xE3a5B3345c97322092382EF4835738fa677fd4A2',
    },
    
    tokenPerBlock: '0.1',
    participantThreshold: 1000,
    isFinished: false,
    earningToken: serializedTokens.ccc,
    sideRewards: [
      {token: 'CRO', percentage: 1},
    ],
  //  supportedCollectionPids: [1],
    mainCollectionWeight: '500',
    performanceFee: '0',
    projectLink: 'https://gangverse.club',
    banner: "/images/stakenft/gangsocial.png",
    avatar: "/images/stakenft/logos/gangverse.png",
   //staticNftImage: "/images/stakenft/logos/cctstake.png",
   //useApi: true,
  },
  
  

].filter((f) => !!f.nftAddresses[ChainId.CRONOS])

export default nftFarmsGangverse
