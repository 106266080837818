const PANCAKE_EXTENDED = 'https://tokens.cronoscats.club/cronoscats-extended.json'
const PANCAKE_TOP100 = 'https://tokens.cronoscats.club/coingecko.json'

export const UNSUPPORTED_LIST_URLS: string[] = []
export const BSC_URLS = [PANCAKE_EXTENDED, PANCAKE_TOP100]

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  PANCAKE_EXTENDED,
  PANCAKE_TOP100,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = []
