import React from 'react'

const MediumSvg = (props) => (
  <svg data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" height="44px" {...props}>
    <defs>
      <linearGradient
        id="a"
        y1={192.51}
        x2={44}
        y2={192.51}
        gradientTransform="matrix(1 0 0 -1 0 214.51)"
        gradientUnits="userSpaceOnUse"
      >
    <stop offset={0} stopColor="#ffffff" />
        <stop offset={1} stopColor="#ffffff" />
      </linearGradient>
      <clipPath id="b">
        <path
          style={{
            fill: 'none',
          }}
          d="M11 11h22v22H11z"
        />
      </clipPath>
    </defs>
    <circle
      cx={22}
      cy={22}
      r={21.5}
      style={{
        stroke: 'url(#a)',
        fill: 'none',
      }}
    />
    <g
      style={{
        clipPath: 'url(#b)',
      }}
    >
      <path
        d="M23.41 22a6.21 6.21 0 0 1-8.58 5.72 6.32 6.32 0 0 1-2-1.34 6.16 6.16 0 0 1-1.34-2A6 6 0 0 1 11 22a6.2 6.2 0 0 1 6.2-6.19 6.17 6.17 0 0 1 5.73 3.82 6 6 0 0 1 .48 2.37Zm6.81 0c0 3.22-1.39 5.82-3.11 5.82S24 25.22 24 22s1.39-5.82 3.1-5.82 3.12 2.6 3.12 5.82ZM33 22c0 2.88-.49 5.22-1.09 5.22s-1.09-2.34-1.09-5.22.49-5.22 1.09-5.22S33 19.12 33 22Z"
        style={{
          fill: '#fff',
          fillOpacity: 0.87,
        }}
      />
    </g>
  </svg>
)

export default MediumSvg
